<template>
    <div>
        <button @click="test">111</button>
    </div>
</template>

<script>
    export default {
        name: "index",
        methods:{
            test(){
                this.$router.replace({name:'test'})
            }
        }
    }
</script>

<style scoped>

</style>
